<template>
  <div class="olMenuDiv">
    <CCard v-if="isMobile()" :class="isMobile()?'mobile-search-toggle-card':''" style="    display: inline-block; ">
      <div :style="{ paddingTop: '10px',paddingBottom:'10px' ,width:window.width +'px',backgroundColor: '#F6C857'}">
        <CRow>
          <CCol class="md-6 text-center">
            <DIV class=" " @click="reset">
              <CIcon class="my-1" height="20" name="cil-action-undo"/>
              <strong class="my-1 ">重新選位</strong>
            </DIV>
          </CCol>
          <CCol class="text-center" style="border-left: 1px solid;}">
            <div aria-controls="features" @click="toggleFeatures">
              <CIcon v-if="featuresOpen" class="my-1" height="25" name="cil-chevron-bottom"/>
              <CIcon v-else class="my-1" height="25" name="cil-chevron-top"/>
              <strong>開始評估</strong>
            </div>
          </CCol>
        </CRow>
      </div>
    </CCard>
    <CCard v-show-slide="featuresOpen" :class="isMobile()?'mobile-search-card':'default-search-card'"
           :style="{ width:isMobile()!=true?'auto':window.width +'px'}">
      <CCardBody :style="isMobile()?'padding: 0.4rem':''" >
       <CRow class="justify-content-center "> 
          <CCol>
              <div class="form-group" role="group"> 
              <label class="" for="industry">選擇產業</label>  
              <div>
                <label v-for="dtype in industryTypeList" :key="dtype" class="label-checkbox"  :class="{ 'checked': (dtype===industryType) }" :for="dtype"> 
                      <input type="radio" v-model="industryType" @click="openIndustryDropdownList" name="industryType" :id="dtype" class="check-day" :value="dtype" />{{ dtype }}
                </label>
              </div> 
                <multiselect ref="refIndustry" id="industry" v-model="industry" :options="industryList" track-by="Objectid" label="Name" :multiple="false" deselect-label="" select-label="" selected-label=""
                :allow-empty="false" :preselectFirst="true" :searchable="true" :clear-on-select="true" :close-on-select="true" :preserve-search="true" placeholder="搜尋產業">  
                 <span slot="noResult">
                沒有與查詢匹配的選項
                </span>
                </multiselect> 
            </div>
              <div role="group" class="form-group">
                <label for="price" class=""> 預期平均客單價 </label>
                <multiselect id="price" v-model="price" :options="priceList" track-by="value" label="label" :multiple="false" deselect-label="" select-label="" selected-label=""
                :allow-empty="false" :preselectFirst="true" :searchable="false" :clear-on-select="true" :close-on-select="true" :preserve-search="true" placeholder="選擇單價">  
                </multiselect>   
            </div>
            <div class="form-group" role="group">
              <label class="" for="radius">服務半徑</label>
                  <multiselect id="radius" v-model="radius" :options="radiusList" track-by="value" label="label" :multiple="false" deselect-label="" select-label="" selected-label=""
                :allow-empty="false" :preselectFirst="true" :searchable="false" :clear-on-select="true" :close-on-select="true" :preserve-search="true" placeholder="選擇半徑">  
                </multiselect>   
            </div>
            <div class="form-group" role="group">
                <label class="" for="voucherCode">報表授權模式</label> 
                <multiselect id="voucherCode" v-model="canUseItem" :options="courses" track-by="Code" label="Name" :multiple="false" deselect-label="" select-label="" selected-label=""
                             :allow-empty="false" :preselectFirst="true" :searchable="false" :clear-on-select="true" :close-on-select="true" :preserve-search="true"  >
                </multiselect> 
            </div> 
            <button v-if="showSet" class="btn btn-rptsearch btn-lg btn-block" type="submit" @click='genreport'><strong>報告產出</strong></button>
            <button v-if="showSet == false" class="btn btn-rptsearch btn-lg btn-block" type="button" @click='samplereport'><strong>範例報告</strong></button>
            <button v-if="!isMobile() && showSet" class="btn btn-rptsearch btn-lg btn-block" type="reset" @click='reset'><strong>重新選位</strong></button>
          </CCol>
        </CRow> 
      </CCardBody>
    </CCard>
    <div class="adjust-modal"> 
      <CModal :show.sync="showNoLicenseModal"
              color="warning"
              title="提醒">
        <p class="text-left">
          您尚未擁有任何報表授權，請參考以下方式取得:
        <ol>
          <li>至右上方選單<a href="/myAccount">「我的交易」</a>，加購取得</li>
          <li>或至左側選單<a href="/Voucher">「兌換碼」</a>，輸入兌換碼取得</li>
        </ol>
        </p>
        <template #footer> 
          <CButton color="warning" @click="showNoLicenseModal=false"><span class="text-white">關閉</span></CButton>
        </template>
      </CModal> 
      <!--new price notice-->
      <CModal :show.sync="showNewPriceNoticeModal" style="z-index: 2;"
              color="warning"
              title="提醒">
        <img src="/img/notification_qu new pricing.png" style="width: 100%;height: 100%;"/>
        <template #footer> 
            <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="skipShowNewPriceNotice" @change="handleSkipNewPriceNotice">
                <label class="form-check-label" for="flexCheckDefault">
                    不再提醒我
                </label>
            </div>
          <CButton color="warning" @click="showNewPriceNoticeModal=false"><span class="text-white">關閉</span></CButton>
        </template>
      </CModal>   
    </div>
  </div>
</template>

<script>
    import axios from 'axios';
    import store from '../util/store';
    import appSettings from '../util/settings';
    import mobile from '../util/mobile';
    import Multiselect from 'vue-multiselect'
    import Vue from 'vue'
    import VShowSlide from 'v-show-slide'

    Vue.use(VShowSlide)
   
    const PRICE_LIST = appSettings.DropDownList.PriceList;
    const RADIUS_LIST = appSettings.DropDownList.RadiusList;

    export default {
        name: 'mapMenu',
        components: { Multiselect },
        props: {},
        data() {
            return {
                industry: null,
                radius: RADIUS_LIST[0],
                price: PRICE_LIST[0],
                industryType: undefined,
                //havSeat: false,
                showSet: false,
                canUseItem: undefined,
                courses: [],
                featuresOpen: this.isMobile() ? false : true,
                showNoLicenseModal: false,
                showNewPriceNoticeModal:false,
                skipShowNewPriceNotice:false,
                totalCount: 0,

                window: {
                    width: window.innerWidth,
                    height: window.innerHeight
                },
                industryList: [],
                industryTypeList: [],
                orginalIndustryTypeList: [],
                priceList: PRICE_LIST,
                radiusList: RADIUS_LIST
            }
        },
        mounted() {
            this.getIndustryList();
            this.getTicketList();

            this.handleNewPriceNotice();
        },
        methods: {
            VoucherList(response) {
                var data = response.data;
                if (!data.IsLogin) {
                    store.commit("clearUser");
                    this.$router.replace({ name: "Login" });
                } else {
                    var voucherList = data.VoucherList;
                    //this.courses.push({"Code":"", "Name":""});
                    let courses = this.courses;
                    //let voucherCode = "";
                    let item = undefined;
                    let totalCount = 0
                    voucherList.forEach(function (voucher) {
                        var vCode = voucher.Code == undefined ? "" : voucher.Code;
                        let courseItem = { "Code": vCode, "Name": voucher.Name, "Count": voucher.Count, "Type": voucher.Type };
                        courses.push(courseItem);

                        if (item === undefined) {
                            item = courseItem;
                        }

                        if (voucher.Count > 0) {
                            //voucherCode = vCode;
                            item = courseItem;
                            totalCount += voucher.Count;
                        }
                    });

                    //this.voucherCode = voucherCode;
                    this.totalCount = totalCount;
                    //this.checkShowSet(voucherCode);
                    this.canUseItem = item;
                    this.showSubmitReport();
                    this.showNoLicenseModal = !this.totalCount > 0;
                }
            },
            //chooseVoucherCode() {
            //    this.showSubmitReport();
            //},
            genreport() {
                if (this.showSet === false) {
                    this.showNoLicenseModal = true;
                } else {

                    this.$emit("genreport", (this.industry == null ? null : this.industry.Objectid), this.radius.value, this.price.value, this.canUseItem.Type, this.canUseItem.Code);
                }
            },
            getIndustryList() {
                axios.post(appSettings.GetIndustryList,
                    {
                        userToken: store.state.token,
                    })
                    .then(this.industryListResult)
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            getTicketList() {
                this.courses = [];
                axios.post(appSettings.VoucherList,
                    {
                        userToken: store.state.token,
                    })
                    .then(this.VoucherList)
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            industryListResult(response) {
                let data = response.data;
                this.orginalIndustryTypeList = data.List;   //orginal Industry list
                this.industryTypeList = ['全部'].concat(data.SubTypeList);   //Industry subType list
                this.industryList = JSON.parse(JSON.stringify(this.orginalIndustryTypeList));  //current Industry dropdownlist

                let industryObjectid = this.$cookies.get("industry");
                if (industryObjectid !== undefined && industryObjectid !== null) {
                    let findIndustry = this.industryList.find(x => x.Objectid == industryObjectid);
                    this.industryType = findIndustry.SubClassType;
                    this.industry = findIndustry;

                }
                else {
                    this.industryType = this.industryTypeList[0];
                    this.industry = this.industryList[0];
                }
                //console.log('industry',this.industry)

            },
            isMobile: function () {
                return mobile.isMobile();
            },
            reset() {
                this.$emit("reset");
                if (this.isMobile()) this.featuresOpen = false;
            },
            samplereport() {
                this.$emit("samplereport");
            },
            showSubmitReport() {
                if (this.canUseItem.Count > 0) {
                    this.showSet = true;
                } else {
                    this.showSet = false;
                }
            },
            toggleFeatures() {
                this.featuresOpen = !this.featuresOpen
            },
            openIndustryDropdownList() {
                this.$refs.refIndustry.isOpen = true;
            },
            handleNewPriceNotice(){
                var haveToShow = new Date() <  new Date("2025-03-03");
                if(haveToShow){
                    const skipNewPriceNotice = localStorage.getItem("skipNewPriceNotice");
                    if (skipNewPriceNotice === "true") {
                        this.showNewPriceNoticeModal = false;
                    }
                    else{
                        this.showNewPriceNoticeModal = true;
                    }
                } 
            },
            handleSkipNewPriceNotice(event){
                const isChecked = event.target.checked; 
                localStorage.setItem("skipNewPriceNotice", isChecked ? "true" : "false"); 
                if (isChecked) {
                     this.showNewPriceNoticeModal = false;
                }
            } 
        },
        watch: {
            industryType: {
                immediate: true,
                handler(newVal, oldVal) {

                    if (newVal === undefined || newVal === null) return;

                    if (oldVal !== undefined) this.industry = null;
                    if (newVal === '全部') {
                        this.industryList = this.orginalIndustryTypeList;
                    }
                    else {
                        this.industryList = this.orginalIndustryTypeList.filter(x => x.SubClassType === newVal);
                    }
                }, 
            }, 
            canUseItem: {
                immediate: true,
                deep: true,
                handler(newVal, oldVal) {
                     if(oldVal==undefined) return;
                     this.showSubmitReport();
                }
            }
        }
    }
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style> 